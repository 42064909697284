import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { GridToolbarFilterButton, GridToolbarContainer } from '@mui/x-data-grid';
// @mui
import { Card, Stack, Button, Popover, MenuItem, Container, Typography, IconButton, Box } from '@mui/material';
import Iconify from '../components/iconify';
import CreatePrompt from 'src/components/prompt/create';
// ----------------------------------------------------------------------
import { useQuery } from 'react-query';
import { get } from 'src/api/promptapi';
import DeletePrompt from 'src/components/prompt/delete';

// ----------------------------------------------------------------------

export default function PromptPage() {
  const [open, setOpen] = useState(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [promptData, setPromptData] = useState(null);
  const [rows, setRows] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    console.log(row);
    setPromptData(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const { data, refetch } = useQuery('getprompt', get, {
    refetchOnWindowFocus: false,
    onSuccess: () => {},
  });
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: false,
      //   flex: 1,
    },
    {
      field: 'space',
      headerName: 'Space',
      width: 150,
      editable: false,
      //   flex: 1,
      renderCell: (params) => {
        const space = params.row.space.join(', ');
        return <>{space}</>;
      },
    },
    {
      field: 'prompt',
      headerName: 'Prompt',
      width: 150,
      editable: false,
      flex: 1,
    },

    {
      field: 'Action',
      headerName: '',
      width: 50,
      sortable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params) => {
        return (
          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, params.row)}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    const newArray = data?.map((obj) => ({ ...obj, id: obj._id }));
    setRows(newArray);
  }, [data]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ py: 2, direction: 'flex', justifyContent: 'space-between' }}>
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <Helmet>
        <title> Prompt | bimera-gpt </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Custom Prompt
          </Typography>
          <Button variant="contained" onClick={() => setOpenCreate(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
            Add New Prompt
          </Button>
        </Stack>

        <Card sx={{ p: 1 }}>
          <Box sx={{ width: '100%' }}>
            <DataGrid
              sx={{
                '&.MuiDataGrid-root': {
                  border: 0,
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none !important',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
                  outline: 'none !important',
                },
              }}
              rowHeight={63}
              rows={rows ? rows : []}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              slots={{
                toolbar: CustomToolbar,
              }}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              disableRowSelectionOnClick
            />
          </Box>
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenCreate(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={() => setOpenDelete(true)} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <CreatePrompt
        openCreate={openCreate}
        setOpenCreate={setOpenCreate}
        promptData={promptData}
        setPromptData={setPromptData}
        refetch={refetch}
        handleCloseMenu={handleCloseMenu}
      />
      <DeletePrompt
        openCreate={openDelete}
        setOpenCreate={setOpenDelete}
        userData={promptData}
        setUserData={setPromptData}
        refetch={refetch}
        handleCloseMenu={handleCloseMenu}
      />
    </>
  );
}
